import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { Container, Nav, Navbar } from "react-bootstrap"
import "./style.scss"
import NavDropdownOnHoverOrClick from "../navdropdownonhoverorclick"
import WorkshopsOffer from "../offersections/workshopsoffer"
import IntensivesOffer from "../offersections/intensivesoffer"
import FerieOffer from "../offersections/ferieoffer"

export default function CustomNavbar(props) {

  const INDEX_PAGE_MIN_OPACITY = 0.5;

  const activeKey = props.pageInfo === undefined ? "" : props.pageInfo.pageName
  const [opacity, updateOpacity] = useState((activeKey === 'index') ? INDEX_PAGE_MIN_OPACITY : 0)

  useEffect(() => {
    // if we want code to run on component mount (because [] is in second argument)
    // code run on every change of opacity because [opacity] is in second argument)
    window.onscroll = () => {
      const newScrollHeight = Math.ceil(window.scrollY / 5) * 10
      let opacityNow = Math.min(newScrollHeight / 100, 0.9)
      if (activeKey === 'index') opacityNow = Math.max(opacityNow, INDEX_PAGE_MIN_OPACITY);
      if (opacity !== opacityNow) {
        updateOpacity(opacityNow)
      }
    }
  }, [opacity])


  let color = `rgba(212,168,140, ${opacity})`
  // rgb(198, 146, 125)
  // #d4a88c

  return (
    <>
      <Navbar variant="light" expand="lg" id="site-navbar" style={{ backgroundColor: color }}>
        <Container>
          <Link to="/" className="link-no-style">
            <Navbar.Brand as="span">
              <img src={require("../../images/navbarlogo-tr.png")}
                   height="50"
                   className="d-inline-block align-top"
                   alt="profil"
              />
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav activeKey={activeKey}>
              <Link to="/" className="link-no-style">
                <Nav.Link as="span" eventKey="index">
                  START
                </Nav.Link>
              </Link>
              <NavDropdownOnHoverOrClick linkTo="/o-nas" eventKey={"o-nas"} name={"O NAS"}
                                         subnavs={[["/o-nas#profil", "O Profilu"], ["/o-nas#instruktorzy", "Instruktorzy"], ["/o-nas#zespol", "Zespół"], ["/o-nas#osiagniecia", "Osiągnięcia"]]}/>
              <NavDropdownOnHoverOrClick linkTo="/oferta" eventKey={"oferta"} name={"OFERTA"}
                                         subnavs={[["/oferta#zajecia", "Oferta zajęć"], ["/oferta#indywidualne", "Zajęcia indywidualne"], /*["/oferta#obozy", "Obozy treningowe"],*/ ["/oferta#warsztaty", "Sunday workshops"],
                                           ["/oferta#intensives", "Dance Intensives"],
                                           ["/oferta#weekend", "Taneczny weekend"],
                                           ["/oferta#ferie", "Ferie dla dzieci"],
                                           ["/oferta#wakacje", "Wakacje dla dzieci"],
                                         ]}/>

              <Link to="/ferie2024" className="link-no-style snowflakes" hidden>
                <Nav.Link as="span" eventKey="ferie2024">
                  FERIE 2024
                </Nav.Link>
              </Link>
              <Link to="/wakacje2024" className="link-no-style sunny" hidden>
                <Nav.Link as="span" eventKey="wakacje2024">
                  WAKACJE 2024
                </Nav.Link>
              </Link>
              <Link to="/grafik" className="link-no-style">
                <Nav.Link as="span" eventKey="grafik">
                  GRAFIK
                </Nav.Link>
              </Link>
              <Link to="/cennik" className="link-no-style">
                <Nav.Link as="span" eventKey="cennik">
                  CENNIK
                </Nav.Link>
              </Link>
              <Link to="/zapisy" className="link-no-style">
                <Nav.Link as="span" eventKey="zapisy">
                  ZAPISY
                </Nav.Link>
              </Link>
              <Link to="/galeria" className="link-no-style">
                <Nav.Link as="span" eventKey="galeria">
                  GALERIA
                </Nav.Link>
              </Link>
              <Link to="/kontakt" className="link-no-style">
                <Nav.Link as="span" eventKey="kontakt">
                  KONTAKT
                </Nav.Link>
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}
